<!--
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-17 18:00:30
 * @LastEditTime: 2024-09-26 14:03:54
-->
<template>
  <RouterView />
  <!-- 加载动态弹窗 -->
  <div class="overlay" v-show="PublicStores.loadingShow">
    <div class="content">
      <div class="loader"></div>
      <el-text class="mx-1" type="primary">{{PublicStores.loadingText}}</el-text>
    </div>
  </div>
</template>   

<script setup>
import setThemsColor from "@/utils/theme.js";
import { PublicStore } from "@/tools/pinia/store.js";
import { onMounted } from "vue";
// 设置主题色
// setThemsColor("#6F9C29");

onMounted(() => {
  document.addEventListener("contextmenu", (event) => event.preventDefault());
});

// 国际化语言（默认跟随浏览器语言）
const userLanguage = navigator.language.substring(0, 2);
const { locale } = useI18n();
onMounted(() => {
  setLanguage();
});
//获取语言
let PublicStores = PublicStore();
function setLanguage() {
  let i18nLocal = window.g.langlist.find((item) => item.title == userLanguage);
  PublicStores.changeLanguage(i18nLocal.value);
  locale.value = userLanguage || "zh";
}
</script>
<style  scoped lang="scss">
.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(204, 204, 204, 0.3);
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loader {
    border: 4px solid rgba(204, 204, 204, 0.3); /* 灰色的边框 */
    border-top: 4px solid #6f9c29; /* 旋转的边框颜色 */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite; /* 旋转动画 */
    margin-bottom: 16px;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.custom-context-menu {
  width: 200px;
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #c9cfd8;
  box-shadow: 3px 3px 12px 0px #13141540;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 0 6px 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      height: 40px;
      line-height: 40px;
      padding: 0 24px;
      cursor: pointer;
    }
    li:hover {
      background: #d9e7ba;
    }
  }
}
</style>
